import React, { useContext } from "react"
import { navigate } from "gatsby"
import { FaSearch } from "react-icons/fa"
import { SearchContext } from "../context/SearchContext"
const SearchMobile = () => {
  const { setText, text, setDataSearch } = useContext(
    SearchContext
  )

  const handleChangeText = event => {
    setDataSearch([])
    const textSearch = event.target.value
    setText({ text: textSearch })
  }

  return (
    <>
      <div className="search-box">
        <input
          type="text"
          placeholder="求人 キーワード検索"
          className="input-text"
          onChange={e => handleChangeText(e)}
        />
        <FaSearch
          className="faSearch"
          onClick={() => {
            navigate(`/search/?type=jobs&keyword=${text.text}`)
          }}
        />
      </div>
    </>
  )
}

export default SearchMobile
