import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import { ModalStyle } from "../components/styled"
import { FaCaretDown, FaSearch } from "react-icons/fa"
import { SearchJobContext } from "../context/SearchJobContext"
import { useStaticQuery, graphql } from "gatsby"
import Modal from "react-modal"

Modal.setAppElement("#___gatsby")

const SearchJobNew = () => {
  const dataQuery = useStaticQuery(graphql`
    {
      allStrapiType(sort: { order: ASC, fields: FilterOrder }) {
        nodes {
          name
          title
          strapiId
          image {
            publicURL
          }
        }
      }
      allStrapiLocationItems(sort: { fields: order, order: ASC }) {
        nodes {
          name
          order
          location_items_groups {
            name
          }
        }
      }
      allStrapiMarks(sort: { order: ASC, fields: FilterOrder }) {
        nodes {
          name
          strapiId
        }
      }
    }
  `)
  const dataTypes = dataQuery.allStrapiType.nodes
  const dataMarks = dataQuery.allStrapiMarks.nodes
  const dataFilterLoc = dataQuery.allStrapiLocationItems.nodes
  
  const {
    mark,
    setMark,
    tagLocation,
    setTagLocation,
    tagOnly,
    setTagOnly,
    allTag,
    setAllTag,
  } = useContext(SearchJobContext)

  const handleChangeSearchInput = event => {
    setAllTag([])
    setMark([])
    setTagLocation([])
    setTagOnly([])
    const valueInput = event.target.value
    setAllTag(valueInput)
  }
  const [locSub, setLocSub] = useState([])
  const [locSub2, setLocSub2] = useState([])
  useEffect(() => {
    for (let o = 0; o < 3; o++) {
      setLocSub(prevState => [...prevState, dataFilterLoc[o]])
    }
    for (let p = 3; p < dataFilterLoc.length; p++) {
      setLocSub2(prevState => [...prevState, dataFilterLoc[p]])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [openModal, setOpenModal] = useState(false)
  const openFilterModal = () => {
    setMark([])
    setTagLocation([])
    setTagOnly([])
    setOpenModal(true)
  }
  const closeFilterModal = () => {
    setOpenModal(false)
  }

  const handleChangeType = () => {
    const dataSetMark = []
    const ELEMENT_CHECK = document.querySelectorAll(".check-types")
    for (let i = 0; i < ELEMENT_CHECK.length; i++) {
      if (ELEMENT_CHECK[i].checked === false) {
      } else {
        dataSetMark.push(ELEMENT_CHECK[i].value)
      }
    }
    setMark(dataSetMark)
  }

  const handleChangeOnly = () => {
    const dataSetMark = []
    const ELEMENT_CHECK = document.querySelectorAll(".check-only")
    for (let i = 0; i < ELEMENT_CHECK.length; i++) {
      if (ELEMENT_CHECK[i].checked === false) {
      } else {
        dataSetMark.push(ELEMENT_CHECK[i].value)
      }
    }
    setTagOnly(dataSetMark)
  }

  const handleSelectAll = () => {
    let dataSetMark = []
    const ELEMENT_CHECK = document.querySelectorAll(".check-types")
    const ELEMENT_SELECT_ALL = document.querySelector(".check-all")
    for (let i = 0; i < ELEMENT_CHECK.length; i++) {
      if (ELEMENT_SELECT_ALL.checked === false) {
        ELEMENT_CHECK[i].checked = true
        dataSetMark.push(ELEMENT_CHECK[i].value)
      } else {
        ELEMENT_CHECK[i].checked = false
      }
    }
    setMark(dataSetMark)
  }

  const handleChangeLocation = (group, nameLoc, position) => {
    let dataSetMark = []
    const ELEMENT_CHECK = document.querySelectorAll(".subgroups")
    const ELEMENT_SELECT_ALL = document.querySelectorAll(".all-group")
    if (group.length !== 0) {
      group.forEach(element => {
        const nameLocation = nameLoc === "その他" ? element.name : nameLoc + "（" + element.name + "）"
        for (let q = 0; q < ELEMENT_CHECK.length; q++) {
          if (nameLocation === ELEMENT_CHECK[q].value) {
            if (ELEMENT_SELECT_ALL[position].checked === false) {
              ELEMENT_CHECK[q].checked = false
            } else {
              ELEMENT_CHECK[q].checked = true
            }
          }
        }
      })
    }
    const ELEMENT_CHECK_LOC = document.querySelectorAll(".check-location")
    for (let i = 0; i < ELEMENT_CHECK_LOC.length; i++) {
      if (ELEMENT_CHECK_LOC[i].checked === false) {
      } else {
        dataSetMark.push(ELEMENT_CHECK_LOC[i].value)
      }
    }
    setTagLocation(dataSetMark)
  }
  const handleChangeSubLocation = () => {
    let dataSetMark = []
    const ELEMENT_CHECK_LOC = document.querySelectorAll(".check-location")
    for (let i = 0; i < ELEMENT_CHECK_LOC.length; i++) {
      if (ELEMENT_CHECK_LOC[i].checked === false) {
      } else {
        dataSetMark.push(ELEMENT_CHECK_LOC[i].value)
      }
    }
    setTagLocation(dataSetMark)
  }
  const handleSelectAllLocation = () => {
    let dataSetMark = []
    const ELEMENT_CHECK = document.querySelectorAll(".check-location")
    const ELEMENT_SELECT_ALL = document.querySelector(".check-all-location")
    for (let i = 0; i < ELEMENT_CHECK.length; i++) {
      if (ELEMENT_SELECT_ALL.checked === false) {
        ELEMENT_CHECK[i].checked = true
        dataSetMark.push(ELEMENT_CHECK[i].value)
      } else {
        ELEMENT_CHECK[i].checked = false
      }
    }
    setTagLocation(dataSetMark)
  }
  const handleSearchMark = (typeSearch, e) => {
    e.preventDefault()
    if (typeSearch === "modal") {
      navigate(
        `/jobsearch/?types=${mark}&marks=${tagOnly}&locations=${tagLocation}`
      )
    } else {
      navigate(`/jobsearch/?search=${allTag}`)
    }
  }

  return (
    <>
      <div className="search-job">
        <form
          onSubmit={e => handleSearchMark("nomodal", e)}
          name="gs"
          method="get"
        >
          <div className="search-job_search">
            <input
              type="text"
              placeholder="検索したいキーワード"
              onChange={e => handleChangeSearchInput(e)}
            />
            <button type="submit" aria-label="Search">
              <FaSearch className="faSearch" />
            </button>
          </div>
        </form>
        <button className="search-job_button" onClick={openFilterModal}>
          詳細検索
          <FaCaretDown className="FaCaretDown" />
        </button>
      </div>
      <Modal
        isOpen={openModal}
        style={ModalStyle}
        onRequestClose={closeFilterModal}
      >
        <form
          onSubmit={e => handleSearchMark("modal", e)}
          name="ns"
          method="get"
        >
          <div className="content-modal">
            <div className="container checkbox-group">
              <div className="row u-margin-top-1-5">
                <div className="col-lg-3 col-md-3 checkbox-group__all search-responsive">
                  <h5>職種で探す</h5>
                  <div className="u-position-relative checkbox-responsive">
                    <input
                      type="checkbox"
                      id="checkAll"
                      className="check-all"
                    />
                    <div
                      onClick={handleSelectAll}
                      role="button"
                      tabIndex="0"
                      onKeyDown={e => {
                        if (e.key === "Enter" || e.key === " ") {
                          handleSelectAll()
                        }
                      }}
                    >
                      <label htmlFor="checkAll">全て選択</label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 checkbox-group__group-types">
                  {dataTypes.map((types, i) => (
                    <div className="checkbox-group__group-types--input" key={i}>
                      <input
                        type="checkbox"
                        className="check-marks check-types"
                        value={types.name}
                        id={`${types.name}${i}`}
                        onChange={handleChangeType}
                      />
                      <label htmlFor={`${types.name}${i}`}>{types.name}</label>
                    </div>
                  ))}
                </div>
              </div>
              {/* FOR LOCATION */}
              <div className="row u-margin-top-1-5">
                <div className="col-lg-3 col-md-3 checkbox-group__all search-responsive">
                  <h5>地名で探す</h5>
                  <div className="u-position-relative checkbox-responsive">
                    <input
                      type="checkbox"
                      id="checkAllLocation"
                      className="check-all-location"
                    />
                    <div
                      onClick={handleSelectAllLocation}
                      role="button"
                      tabIndex="0"
                      onKeyDown={e => {
                        if (e.key === "Enter" || e.key === " ") {
                          handleSelectAllLocation()
                        }
                      }}
                    >
                      <label htmlFor="checkAllLocation">全て選択</label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 checkbox-group__group-types">
                  <div className="checkbox-group__group-types__container-checkeds">
                    <div className="checkets">
                      {locSub.map((location, i) => (
                        <div key={i}>
                          <div className="checkbox-group__group-types">
                            <input
                              type="checkbox"
                              className={`check-marks check-location all-group`}
                              value={location.name}
                              id={location.name}
                              onChange={() =>
                                handleChangeLocation(
                                  location.location_items_groups,
                                  location.name,
                                  i
                                )
                              }
                            />
                            <label htmlFor={location.name}>
                              {location.name}
                            </label>
                          </div>
                      
                          {location.location_items_groups.map((loc, j) => (
                            <div className="subcheckeds" key={j}>
                              <>
                                <input
                                  type="checkbox"
                                  value={`${location.name}（${loc.name}）`}
                                  id={loc.name}
                                  className="check-marks check-location subgroups"
                                  onChange={handleChangeSubLocation}
                                />
                                <label htmlFor={loc.name}>{loc.name}</label>
                              </>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                    <div className="checkets">
                      {locSub2.map((location, i) => (
                        <div key={i}>
                          <div className="checkbox-group__group-types">
                            <input
                              type="checkbox"
                              className={`check-marks check-location all-group`}
                              value={location.name}
                              id={location.name}
                              onChange={() =>
                                handleChangeLocation(
                                  location.location_items_groups,
                                  location.name,
                                  i + 3
                                )
                              }
                            />
                            <label htmlFor={location.name}>
                              {location.name}
                            </label>
                          </div>
                          {location.location_items_groups.map((loc, k) => (
                            <div className={`subcheckeds ${location.order === 11 ? "hidde-location-group" : ""}`} key={k}>
                              <>
                                <input
                                  type="checkbox"
                                  value={`${location.order === 11 ? loc.name : location.name + "（" + loc.name + "）"}`}
                                  id={loc.name}
                                  className="check-marks check-location subgroups"
                                  onChange={handleChangeSubLocation}
                                />
                                <label htmlFor={loc.name}>{loc.name}</label>
                              </>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row u-margin-top-1-5 u-padding-bottom-5">
                <div className="col-lg-3 col-md-3 checkbox-group__all search-responsive">
                  <h5>メリットで探す</h5>
                </div>
                <div className="col-lg-9 col-md-9 checkbox-group__group-types checkbox-responsive">
                  {dataMarks.map((mark, i) => (
                    <div className="checkbox-group__group-types--input" key={i}>
                      <input
                        type="checkbox"
                        className="check-marks check-only"
                        value={mark.name}
                        id={mark.name}
                        onChange={handleChangeOnly}
                      />
                      <label htmlFor={mark.name}>{mark.name}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="u-text-center content-button-fix">
            <button className="btn-filter-check" type="submit">
              検索
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default SearchJobNew
