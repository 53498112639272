import React from "react"
import Slider from "react-slick"
import Moment from "react-moment"
import { Link } from "@reach/router"

const NewsCarousel = ({ newsCard }) => {
  // Arrows style
  const arrowLeft = require("../images/home/carrousel-arrow-left.svg")
  const arrowRight = require("../images/home/carrousel-arrow-right.svg")

  function SampleNextArrow(props) {
    const { className, onClick } = props
    return (
      <div
        className={className}
        onClick={onClick}
        role="button"
        tabIndex="0"
        onKeyDown={e => {
          if (e.key === "Enter" || e.key === " ") {
            onClick()
          }
        }}
      >
        <img src={arrowLeft} alt="carrousel-arrow-left" />
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props
    return (
      <div
        className={className}
        onClick={onClick}
        role="button"
        tabIndex="0"
        onKeyDown={e => {
          if (e.key === "Enter" || e.key === " ") {
            onClick()
          }
        }}
      >
        <img src={arrowRight} alt="carrousel-arrow-right" />
      </div>
    )
  }

  // Config Settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="col-container">
      <Slider {...settings}>
        {newsCard.map(card => (
          <div key={card.strapiId} className="flex-center-card">
            <Link to={"/info/" + card.Slug}>
              <div className="card-news">
                <div className="content">
                  <img
                    src={card.Image.publicURL}
                    alt={"Article_" + card.strapiId}
                  />
                  <div className="content_info">
                    <p className="title">{card.Title}</p>
                    <p className="description">{card.Description}</p>
                    <div className="cat_date">
                      <p className="categorie">
                        {card.article_categories[0].Name}
                      </p>
                      <p className="date">
                        <Moment format="YYYY年 MM月 DD日">
                          {card.DatePublication}
                        </Moment>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default NewsCarousel
