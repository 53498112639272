import React, { useState } from "react"
import { Link, useStaticQuery } from "gatsby"
import useTypes from "../hooks/useTypes"
import useBestJobs from "../hooks/useBestJobs"
import useMarks from "../hooks/useMarks"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Seo } from "../components/seo"
//import useLocation from "../hooks/useLocation"
import { ContactSection } from "../components/ContactSection"
import JobCarousel from "../components/JobCarrusel"
import NewsCarousel from "../components/NewsCarrusel"
import SearchJobNew from "../components/SearchJobNew"
import SearchMobile from "../components/SearchMobile"

const IndexPage = () => {
  const dataTypes = useTypes()
  const dataBestJobs = useBestJobs()
  const dataMarks = useMarks()
  const locationListItems = [
    {
      id: "0",
      name: "アグアスカリエンテス州",
      link: "アグアスカリエンテス州",
    },
    {
      id: "1",
      name: "グアナファト州",
      link: "グアナファト州",
    },
    {
      id: "2",
      name: "ケレタロ州",
      link: "ケレタロ州",
    },
    {
      id: "3",
      name: "サン・ルイス・ポトシ州",
      link: "サン・ルイス・ポトシ州",
    },
    {
      id: "4",
      name: "ヌエボレオン州",
      link: "ヌエボレオン州",
    },
    {
      id: "5",
      name: "メキシコ連邦区（メキシコシティ）",
      link: "メキシコ連邦区（メキシコシティ）",
    },
    {
      id: "6",
      name: "バハ・カリフォルニア州",
      link: "バハ・カリフォルニア州",
    },
    {
      id: "7",
      name: "ハリスコ州",
      link: "ハリスコ州",
    },
    {
      id: "8",
      name: "その他",
      link: "その他",
      desc: "others",
    },
  ]

  const query = useStaticQuery(graphql`
    {
      allStrapiJobs(
        sort: { order: DESC, fields: DatePublication }
        limit: 8
        filter: { Archived: { eq: false }, Language: { eq: "Japanese" } }
      ) {
        nodes {
          JobDescription
          JobCategorie
          Allowances
          HolidayVacation
          CompanyProfile
          Insurance
          Manualid
          Salary
          SalaryIncrease
          Title
          WorkingTime
          Archived
          DatePublication(formatString: "YYYY年 MM月 DD日")
          locations {
            name
          }
          marks {
            name
          }
          types {
            name
            image {
              publicURL
            }
          }
        }
      }
      allStrapiTitles(
        limit: 5
        sort: { order: DESC, fields: DatePublication }
      ) {
        nodes {
          strapiId
          Content
          Title
          Description
          Slug
          Image {
            publicURL
          }
          LastUpdate
          DatePublication
          article_categories {
            Name
            Slug
          }
        }
      }

      file(relativePath: { eq: "03_slide_0222.webp" }) {
        publicURL
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const imageLog = query.file.publicURL
  const dataJobs = query.allStrapiJobs.nodes

  const dataArticles = query.allStrapiTitles.nodes

  // Arrow right
  const arrowRight = require("../images/home/arrow-right.svg")
  // Home img
  const logoMexico = require("../images/919_mex_logo_800_retina.webp")
  const homeQuick = require("../images/home/home-quick.svg")
  const homeQuickShadow = require("../images/home/home-quick-shadow.svg")
  const topFive = require("../images/home/top-five.svg")
  const title_banner = require("../images/home/title-banner.svg")
  const mobile_banner = require("../images/home/mobile-hero.webp")
  //Gif
  const mainVideoBanner = require("../images/home/919m-hero.mp4")

  //Banners list img and links
  const banners1 = [
    {
      img: require("../images/home/banners/keijiban-banner.svg"),
      url: "https://919mexico-keijiban.com/",
    },
    {
      img: require("../images/home/banners/jinjibu-banner.svg"),
      url: "https://www.919mexico.com/mexico-jinjibu/",
    },
    {
      img: require("../images/home/banners/recruit-banner.svg"),
      url: "https://www.919mexico.com/recruit/",
    },
    {
      img: require("../images/home/banners/concept-banner.svg"),
      url: "http://www.sekai-jinjibu.com/concept/",
    },
    {
      img: require("../images/home/banners/usa-news-banner.svg"),
      url: "https://www.919usanews.com/?s=アメリカの人事部/",
    },
    {
      img: require("../images/home/banners/centre-banner.svg"),
      url: "https://www.centrepeople.com/jp/article/tag/イギリスの人事部/",
    },
  ]

  const banners2 = [
    {
      img: require("../images/home/banners/spanish-online-banner.svg"),
      url: "https://spanish-online.jp/",
    },
    {
      img: require("../images/home/banners/mexinavi-banner.svg"),
      url: "https://mexinavi.com/",
    },
    {
      img: require("../images/home/banners/linguaclub-banner.svg"),
      url: "https://linguaclub.jp/",
    },
  ]

  // Mobile expands
  const initialExpandList = [
    { id: 0, exp: "no-expand", rotation: "no-h3-rot", height: "" },
    { id: 1, exp: "no-expand", rotation: "no-h3-rot", height: "" },
    { id: 2, exp: "no-expand", rotation: "no-h3-rot", height: "" },
  ]
  const [expandList, setExpandList] = useState(initialExpandList)
  function handleClickExpand(listId) {
    const expand = expandList.map(list => {
      if (list.id === listId) {
        let contentElement = document.getElementById("expand" + listId)
        let contentHeight = contentElement.scrollHeight
        return {
          ...list,
          height: contentHeight,
          exp: list.exp === "no-expand" ? "expand" : "no-expand",
          rotation: list.rotation === "no-h3-rot" ? "h3-rotation" : "no-h3-rot",
        }
      } else {
        return list
      }
    })
    setExpandList(expand)
  }

  // job list component
  const jobList = () => {
    return (
      <ul className="home-lists job-list">
        {dataTypes.map((works, i) => (
          <li key={"work_" + i}>
            <Link to={`/jobs-search/${works.name}`}>{works.name}</Link>
          </li>
        ))}
      </ul>
    )
  }

  // location List component
  const locationList = () => {
    return (
      <ul className="home-lists location-list mb-40">
        {locationListItems.map(loc => (
          <li key={"loc_" + loc.id}>
            <Link to={"/jobsearch/?types=&marks=&locations=" + loc.link}>
              {loc.name}
            </Link>
          </li>
        ))}
      </ul>
    )
  }

  // mark list component
  const markList = () => {
    return (
      <>
        <div className="mb-30">
          <SearchJobNew />
        </div>
        <ul className="home-lists mark-list mb-40">
          {dataMarks.map((mark, i) => (
            <li key={"mark_" + i}>
              <Link to={"/job-search/" + mark.name}> {mark.name}</Link>
            </li>
          ))}
        </ul>
      </>
    )
  }

  return (
    <>
      <Seo
        title={`メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
        image={imageLog}
      />
      <div className="banner-video">
        <video width="600" autoPlay muted loop className="no-show-mobile">
          <source src={mainVideoBanner} type="video/webm" />
        </video>
        <img
          src={mobile_banner}
          alt="mobile-banner"
          className="show-mobile mob-banner"
        />
        <h1 aria-label="メキシコで働こう！">
          <img src={title_banner} alt="title banner" className="title-banner" />
        </h1>
        <Link to="/job-form" className="button">
          <button className="btn-jp">無料相談</button>
        </Link>
      </div>
      <div className="triangle-container"></div>
      <section className="wrapper home-page">
        <div className="show-mobile search-mobile">
          <SearchMobile />
        </div>
        <div className="row">
          <section>
            <h2>最新の求人からお仕事を探す</h2>
            <div className="job-carrousel max-width-size">
              <JobCarousel jobCard={dataJobs} />
            </div>
            <div className="link-arrow">
              <Link to="/jobs-listing">
                <img src={arrowRight} alt="arrow-right" />
                <span>求人一覧を見る</span>
              </Link>
            </div>
          </section>
          <section className="bg-cyan no-show-mobile">
            <div className="home-quick max-width-size">
              <div className="home-quick_imageshape">
                <img src={homeQuick} alt="quick global" className="image" />
                <img
                  src={homeQuickShadow}
                  alt="quick global"
                  className="shape"
                />
              </div>
              <div>
                <img src={logoMexico} alt="main-logo" />
                <p>
                  クイックグローバルメキシコは、メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントです。メキシコ国内だけでなく、日本からの就職・転職もサポートしており、在メキシコの日系企業の採用のご支援も行っています。
                  <br />
                  メキシコでのお仕事のことなら、お気軽にご相談を！
                </p>
                <Link to="/job-form">
                  <button>就職無料相談フォーム</button>
                </Link>
              </div>
            </div>
          </section>
          <section>
            <h2 className="no-show-mobile mb-50">条件からお仕事を探す</h2>
            <div className="max-width-size pl-30 pr-30">
              <div className="lists-desktop">
                <div className="mb-40">
                  <h3>職種から</h3>
                  <div>{jobList()}</div>
                </div>
                <div className="mb-40">
                  <h3>勤務地から</h3>
                  <div>{locationList()}</div>
                </div>
                <div className="mb-40">
                  <h3>キーワードから</h3>
                  <div>{markList()}</div>
                </div>
              </div>
              <div className="lists-mobile">
                <div
                  onClick={() => {
                    handleClickExpand(0)
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyDown={e => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleClickExpand(0)
                    }
                  }}
                >
                  <h3 className={"first-h3 " + expandList[0].rotation}>
                    職種から
                  </h3>
                </div>
                <div
                  className={expandList[0].exp}
                  id="expand0"
                  style={{ height: expandList[0].height + "px" }}
                >
                  {jobList()}
                </div>
                <div
                  onClick={() => {
                    handleClickExpand(1)
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyDown={e => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleClickExpand(1)
                    }
                  }}
                >
                  <h3 className={expandList[1].rotation}>勤務地から</h3>
                  <div
                    className={expandList[1].exp}
                    id="expand1"
                    style={{ height: expandList[1].height + "px" }}
                  >
                    {locationList()}
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleClickExpand(2)
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyDown={e => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleClickExpand(2)
                    }
                  }}
                >
                  <h3 className={expandList[2].rotation}>キーワードから</h3>
                  <div
                    className={expandList[2].exp}
                    id="expand2"
                    style={{ height: expandList[2].height + "px" }}
                  >
                    {markList()}
                  </div>
                </div>
                <div>
                  <Link to="/process" className="link">
                    お仕事紹介の流れ
                  </Link>
                </div>
                <div>
                  <Link to="/employers" className="link">
                    企業のご担当者様
                  </Link>
                </div>
                <div>
                  <Link to="/about-mexico" className="link">
                    メキシコ情報
                  </Link>
                </div>
                <div className="mb-40">
                  <Link to="/about" className="link">
                    会社概要
                  </Link>
                </div>
              </div>
              <div className="link-arrow">
                <Link to="/jobs-listing">
                  <img src={arrowRight} alt="arrow-right" />
                  <span>求人一覧を見る</span>
                </Link>
              </div>
            </div>
          </section>
          <section
            style={{
              backgroundImage: "url(" + topFive + ")",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              backgroundSize: "cover",
            }}
          >
            <div className="max-width-size pt-50 pb-50 pl-30 pr-30">
              <h2 className="mb-30">{dataBestJobs[0].title}</h2>
              <div className="top-five mb-10">
                <ol>
                  {dataBestJobs[0].topjobs.map((job, i) => (
                    <li key={i}>
                      <Link
                        to={`/osusume${job.Link}#matome0${i + 1}`}
                        onClick={() => scrollTo(`#matome0${i + 1}`)}
                      >
                        {job.Title}
                      </Link>
                    </li>
                  ))}
                </ol>
              </div>
              <div className="link-arrow">
                <Link
                  to={"/osusume" + dataBestJobs[0].topjobs[0].Link}
                  style={{ justifyContent: "flex-end" }}
                >
                  <img src={arrowRight} alt="arrow-right" />
                  <span>特集記事を見る</span>
                </Link>
              </div>
            </div>
          </section>
          <section>
            <h2 className="mb-50">メキシコ就活お役立ち記事</h2>
            <div className="news-carrousel max-width-size">
              <NewsCarousel newsCard={dataArticles} />
            </div>
            <div className="link-arrow">
              <Link to="/info">
                <img src={arrowRight} alt="arrow-right" />
                <span>記事一覧を見る</span>
              </Link>
            </div>
          </section>
          <ContactSection />
          <section>
            <ul className="banner-section">
              {banners1.map((banner, i) => (
                <li key={"banner1-sec_" + i}>
                  <a
                    href={banner.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={"banner1-sec_" + i}
                  >
                    <img src={banner.img} alt={"banner_" + i} />
                  </a>
                </li>
              ))}
            </ul>
          </section>
          <section>
            <div
              className="pt-50 pb-50 pl-30 pr-30"
              style={{ backgroundColor: "#F7F7FC" }}
            >
              <h4>協力企業</h4>
              <ul className="banner-section">
                {banners2.map((banner, i) => (
                  <li key={"banner2-sec_" + i}>
                    <a
                      href={banner.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={"banner2-sec_" + i}
                    >
                      <img src={banner.img} alt={"banner_" + i} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default IndexPage
