import { Link } from "@reach/router"
import React from "react"
import Slider from "react-slick"

const JobCarousel = ({ jobCard }) => {
  
  // List of 3 new jobs
  const jobIcons = {
    location: require("../images/home/loc-icon.svg"),
    job: require("../images/home/job-icon.svg"),
    money: require("../images/home/money-icon.svg"),
    time: require("../images/home/time-icon.svg"),
  }
  // Arrows style
  const arrowLeft = require("../images/home/carrousel-arrow-left.svg")
  const arrowRight = require("../images/home/carrousel-arrow-right.svg")

  function SampleNextArrow(props) {
    const { className, onClick } = props
    return (
      <div
        className={className}
        onClick={onClick}
        role="button"
        tabIndex="0"
        onKeyDown={e => {
          if (e.key === "Enter" || e.key === " ") {
            onClick()
          }
        }}
      >
        <img src={arrowLeft} alt="carrousel-arrow-left" />
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props
    return (
      <div
        className={className}
        onClick={onClick}
        role="button"
        tabIndex="0"
        onKeyDown={e => {
          if (e.key === "Enter" || e.key === " ") {
            onClick()
          }
        }}
      >
        <img src={arrowRight} alt="carrousel-arrow-right" />
      </div>
    )
  }

  // Config Settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="col-container">
      <Slider {...settings}>
        {jobCard.map((card) => (
          <div key={"card_"+card?.Manualid} className="flex-center-card">
            <Link to={"/jobs-listing/" + card.Manualid}>
              <div
                className="card"
                style={{
                  backgroundImage: "url(" + card?.types[0]?.image.publicURL + ")",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  objectFit: "cover",
                  backgroundSize: "cover",
                }}
              >
                <div className="content">
                  <p className="title" style={{ height: "82px" }}>
                    {card?.Title}
                  </p>
                  <div className="icon-data">
                    <img
                      src={jobIcons?.location}
                      alt="loc-icon"
                      width={14}
                      height={14}
                    />
                    <p>{card?.locations[0]?.name}</p>
                  </div>
                  <div className="icon-data">
                    <img
                      src={jobIcons?.job}
                      alt="job-icon"
                      width={14}
                      height={14}
                    />
                    <p>{card?.JobCategorie}</p>
                  </div>
                  <div className="icon-data">
                    <img
                      src={jobIcons?.money}
                      alt="money-icon"
                      width={14}
                      height={14}
                    />
                    <p>{card?.Salary}</p>
                  </div>
                  <div className="icon-data">
                    <img
                      src={jobIcons?.time}
                      alt="time-icon"
                      width={14}
                      height={14}
                    />
                    <p>{card?.DatePublication}</p>
                  </div>
                  <ul className="marks">
                    {card.marks.map((mark,i) => (
                      <li key={"mark_"+i}>{mark?.name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default JobCarousel
