import { Link } from "gatsby"
import React from "react"

export const ContactSection = () => {
  return (
    <section className="bg-cyan">
      <div className="contact-section pt-30 pb-30">
        <p>メキシコでのお仕事のことなら、お気軽にご相談を！</p>
        <Link to="/job-form">
          <button>就職無料相談フォーム</button>
        </Link>
      </div>
    </section>
  )
}
